<template>
  <n-modal v-bind="$props" :width="500">
    <Form ref="modalForm" class="n-modal-container" :label-width="120" :model="model" @submit.native.prevent>
      <n-field-text label="domain" :value="model.domain" :disabled="true" />
      <n-field-text label="type" :value="model.configType" :disabled="true" />
      <n-field-text label="key" :value="model.configKey" :disabled="true" />
      <component
        :is="valueComponent"
        v-model="model.configValue"
        label="value"
        true-label="on"
        false-label="off"
        :disabled="disabled"
      />
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';
import { update } from '@/api/system/system-config';

const VALUE_COMPONENTS = {
  int: 'n-field-number',
  long: 'n-field-number',
  date: 'n-field-date',
  string: 'n-field-text',
  boolean: 'n-field-boolean',
  decimal: 'n-field-decimal',
  timestamp: 'n-field-datetime',
};

export default NModal.extend({
  name: 'SystemConfigEditDialog',
  components: { NModal },

  data() {
    return {
      valueComponent: 'n-field-text',
    };
  },

  computed: {
    isBooleanValue() {
      return this.model.configType === 'boolean';
    },
  },

  mounted() {
    this.valueComponent = VALUE_COMPONENTS[this.model.configType];
    if (!this.valueComponent) this.valueComponent = 'n-field-text';
    // Ken 2019-10-29 15:35 boolean raw value的类型是字符串
    if (this.isBooleanValue) this.$set(this.model, 'configValue', this.model.configValue === 'true');
  },

  methods: {
    doValidate() {
      return Promise.resolve({ type: 'SUCCESS' });
    },

    doSubmit() {
      return update(this.model.domain, this.model.id, { version: this.model.version, value: this.model.configValue });
    },
  },
});
</script>
