import { $fetch, $post } from '@/services/axios';

export function fetch(domain) {
  return $fetch(`system/config/${domain}`);
}

export function reset(domain) {
  return $post(`system/config/${domain}/reset`);
}

export function update(domain, id, model) {
  return $post(`system/config/${domain}/${id}`, model);
}
