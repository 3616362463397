<template>
  <index-content>
    <template #options>
      <nb-refresh @on-click="load" />
      <nb-reset @on-ok="onBtnReset" />
    </template>

    <search-condition :label-width="100">
      <n-field-selection
        v-model="domain"
        selection-name="system.config.domain"
        label-align="left"
        label="domain"
        @on-change="load"
      />
    </search-condition>

    <div class="table-responsive">
      <table class="table table-striped table-bordered table-hover text-center n-table">
        <thead>
          <tr>
            <th class="col-weight-10">{{ $t('label.domain') }}</th>
            <th class="col-weight-10">{{ $t('label.configType') }}</th>
            <th class="col-weight-10">{{ $t('label.dynamic') }}</th>
            <th class="col-weight-37">{{ $t('label.key') }}</th>
            <th class="col-weight-20">{{ $t('label.value') }}</th>
            <th class="n-table-timestamp">{{ $t('label.lastUpdate') }}</th>
            <th v-table-action class="action-edit">{{ $t('label.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td>{{ row.domain | hyphen }}</td>
            <td>{{ row.configType | hyphen }}</td>
            <td>
              <n-icon-boolean :value="row.dynamic" />
            </td>
            <td class="n-ellipsis" :title="row.configKey">{{ row.configKey | hyphen }}</td>
            <td class="n-ellipsis" :title="row.configValue">{{ row.configValue }}</td>
            <td>{{ row.updateDatetime | datetime }}</td>
            <td class="n-button-box">
              <nb-modal @on-click="onBtnShowModal(row)"></nb-modal>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </index-content>
</template>

<script>
import SystemView from './view';
import ModalConfig from './ModalConfig';
import { fetch, reset } from '@/api/system/system-config';

export default SystemView.extend({
  name: 'SystemConfig',

  data() {
    return {
      preload: true,
      domain: 'COMMON',
    };
  },

  methods: {
    doLoad() {
      return fetch(this.domain);
    },

    parse(rows) {
      this.records = rows;
    },

    onBtnReset() {
      return reset(this.domain);
    },

    onBtnShowModal(row) {
      this.createModal(ModalConfig, { model: row, on: this });
    },
  },
});
</script>

<style lang="scss" scoped>
.n-table {
  min-width: 900px;
}
</style>
